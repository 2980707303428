import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export interface TypoState {
  isShowForm: boolean;
  text: string;
  comment: string;
  context: string;
}

export const useTypoStore = wrapPiniaStore(
  defineStore('typo', {
    state: () => ({
      text: '',
      comment: '',
      context: '',
      isShowForm: false,
    }),

    actions: {
      addTypoTextAndShowForm(text: string, context: string) {
        this.text = text;
        this.context = context;
        this.isShowForm = true;
      },

      updateTypoComment(comment: string) {
        this.comment = comment;
      },

      hideTypoForm() {
        this.$reset();
      },

      async sendTypoData() {
        const comment = this.comment || 'Нет комментария';
        const link = `<a href="${window.location.href}">${document.title || 'Ссылка'}</a>`;
        const text = `${this.context}\r\n\r\n<i>Комментарий: ${comment}</i>\r\n\r\n${link}`;

        const data = {
          action: 'sendtypo',
          text: encodeURIComponent(text),
        };

        const { $wordpressApi } = useNuxtApp();
        await $wordpressApi.sendTypoData(data);
        this.hideTypoForm();
      },
    },
  }),
);
