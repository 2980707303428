<template>
  <div>
    <div
      class="typo-form__overlay"
      @click="close"
    />
    <form
      ref="container"
      class="typo-form"
      @click.stop="NOOP"
      @submit="onSubmit"
    >
      <p
        class="typo-form__text"
        v-text="`«${computedText}»`"
      />
      <input
        class="typo-form__comment"
        type="text"
        :maxlength="commentMaxLength"
        placeholder="Комментарий (необязательно)"
        :value="comment"
        @change="onChangeComment"
      />
      <button
        class="typo-form__submit"
        type="submit"
      >
        Отправить
      </button>
      <button
        class="typo-form__close-button"
        @click="close"
      >
        <SvgAsset
          class="typo-form__close-button-icon"
          :content="closeContent"
        />
      </button>
    </form>
  </div>
</template>

<script lang="ts">
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import { NOOP } from '~/utils';
  import PageScroll from '~/libs/PageScroll';
  import closeContent from '~/assets/images/close.svg?raw';

  export interface Props {
    text: string;
    comment: string;
  }

  export default defineNuxtComponent({
    name: 'TypoForm',

    components: {
      SvgAsset,
    },

    props: {
      text: {
        type: String,
        required: true,
      },
      comment: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      commentMaxLength: 255,
    }),

    computed: {
      computedText(): string {
        const { text } = this;
        return text.length > 210 ? `${text.trim().substring(0, 211)}...` : text;
      },
      closeContent: () => closeContent,
    },

    mounted() {
      PageScroll.disable();

      window.addEventListener('keyup', (event: KeyboardEvent): void => {
        if (event.code === 'Escape') {
          this.close();
        }
      });
    },

    beforeUnmount() {
      const target = this.$refs.container as HTMLFormElement;
      PageScroll.enable(target);
    },

    methods: {
      NOOP,

      onSubmit(event: Event): void {
        event.preventDefault();
        this.$emit('form-submit');
      },

      onChangeComment(event: Event): void {
        const value = (event.target as HTMLTextAreaElement).value;
        this.$emit('change-comment', value);
      },

      close(): void {
        this.$emit('close');
      },
    },
  });
</script>

<style lang="scss" scoped>
  $overlayBackground: #000000;
  $hoverColor: #d44b3f;
  $placeholderColor: #777474;
  $borderColor: #e7e7e7;
  $closeIconColor: #969698;
  $closeIconHoverColor: black;

  .typo-form__overlay {
    position: fixed;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    background-color: $overlayBackground;
    opacity: 0.6;
    z-index: 13;
  }

  .typo-form {
    position: fixed;
    left: 50%;
    top: 50%;

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 78px 24px;
    min-height: 320px;
    border-radius: 8px;

    z-index: 14;
    background-color: white;
    transform: translate(-50%, -50%);

    @include tablet {
      max-width: 640px;
    }
  }

  .typo-form__text {
    @include fontH4;

    margin-bottom: 16px;
  }

  .typo-form__comment {
    @include fontTextBtn;
    font-weight: normal;
    height: 48px;
    margin-bottom: 24px;

    border: 1px solid $borderColor;
    padding: 0 16px;
    border-radius: 2px;
    outline: none;

    &::placeholder {
      color: $placeholderColor;
    }
  }

  .typo-form__submit {
    @include fontTextBtn;

    width: 288px;
    height: 48px;
    padding: 8px;
    align-self: center;
    border-radius: 2px;
    cursor: pointer;
    background-color: var(--main-color);
    color: white;
    border: none;
    outline: none;

    @include hover {
      background: var(--hover-color);
    }
  }

  .typo-form__close-button {
    position: absolute;
    right: 16px;
    top: 16px;

    display: inline;
    width: 32px;
    height: 32px;

    border: none;
    background: none;
    cursor: pointer;
    outline: none;

    .typo-form__close-button-icon {
      :deep() {
        path {
          fill: $closeIconColor;
        }
      }
    }

    @include hover {
      .typo-form__close-button-icon {
        :deep() {
          path {
            fill: $closeIconHoverColor;
          }
        }
      }
    }
  }

  .typo-form__close-button-icon {
    width: 32px;
    height: 32px;
  }
</style>
