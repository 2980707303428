<template>
  <TypoForm
    :text="text"
    :comment="comment"
    @form-submit="onSubmit"
    @close="onClose"
    @change-comment="onChangeComment"
  />
</template>

<script lang="ts">
  import { pushBadge } from '~/components/Pusher/websockets-functions';
  import TypoForm from '~/components/TypoForm.vue';
  import { useTypoStore } from '~/store/typos';

  export default defineNuxtComponent({
    name: 'TypoFormContainer',

    components: { TypoForm },

    setup() {
      const typoStore = useTypoStore();

      return { typoStore };
    },

    computed: {
      text() {
        return this.typoStore.text;
      },
      comment() {
        return this.typoStore.comment;
      },
    },

    methods: {
      async onSubmit() {
        try {
          await this.typoStore.sendTypoData();
          pushBadge({ content: 'Вы сообщили об ошибке в тексте. Спасибо за помощь!' });
        } catch (e) {
          pushBadge({
            content: 'Не удалось отправить сообщение об ошибке. Пожалуйста, попробуйте еще раз.',
            isError: true,
          });
        }
      },
      onChangeComment(value: string) {
        this.typoStore.updateTypoComment(value);
      },
      onClose() {
        this.typoStore.hideTypoForm();
      },
    },
  });
</script>
