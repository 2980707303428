<template lang="pug">
    .safari-push-window(@click="handleCloseClick")
        .safari-push-window__modal(@click.stop="() => {}")
            button.safari-push-window__close(@click="handleCloseClick")
                include ./images/close.svg
            .safari-push-window__title
                include ./images/bell.svg
                span Получать уведомления о лучших статьях
            .safari-push-window__buttons
                button.safari-push-window__button.safari-push-window__button--no(@click="handleCloseClick") Не сейчас
                button.safari-push-window__button.safari-push-window__button--yes(@click="handleYesOption") Подписаться
</template>

<script lang="ts">
  import Cookies from 'js-cookie';
  import { SAFARI_COOKIE_NAME } from '~/constants/config';

  import { getPushNotificationsManager } from '~/plugins/push-notifications.client';
  import { useUIStore } from '~/store/ui';

  export default defineNuxtComponent({
    name: 'SafariPushWindowContainer',
    methods: {
      handleCloseClick(): void {
        useUIStore().hideSafariPushDialog();
        Cookies.set(SAFARI_COOKIE_NAME, '1', { expires: 7, path: '/' });
      },
      handleYesOption(): void {
        const pm = getPushNotificationsManager(this.$pushApi);
        pm.requestSafariPermission();
        useUIStore().hideSafariPushDialog();
      },
    },
  });
</script>

<style lang="scss">
  .safari-push-window {
    // background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    &__modal {
      position: relative;
      margin: 104px 0 0 32px;
      background: white;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      max-width: 440px;
      padding: 32px 40px 32px 16px;
    }
    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      svg {
        margin-right: 8px;
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
    }
    &__button {
      @include resetButtonAppearance;
      width: 184px;
      height: 40px;
      border-radius: 2px;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & + & {
        margin-left: 16px;
      }

      &--yes {
        background: #2962f9;
        color: white;
        @include hover {
          background: #264eb1;
        }
      }
      &--no {
        background: white;
        color: #4c4c4c;
        border: 2px solid rgba(0, 0, 0, 0.08);
        @include hover {
          color: black;
        }
      }
    }
    &__close {
      @include resetButtonAppearance;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      @include hover {
        svg path[fill] {
          fill: black;
        }
      }
    }
  }
</style>
