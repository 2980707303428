<template>
  <ClientOnly>
    <div class="the-badges__wrapper">
      <div class="the-badges">
        <transition-group>
          <div
            v-for="badge in badges"
            :key="badge.id"
            :class="getBadgeClasses(badge)"
            @click="clickBadge(badge)"
          >
            <div
              v-if="!badge.isError && badge.image"
              class="the-badges__item-image"
            >
              <img :src="badge.image" />
            </div>
            <img
              v-if="badge.isError"
              class="the-badges__item-image"
              alt=""
              :src="errorSrc"
            />
            <div
              class="the-badges__item-text"
              v-html="badge.content"
            />
            <img
              v-if="badge.isError"
              class="the-badges__item-close"
              :src="closeSrc"
              alt=""
              @click.stop="removeBadge(badge.id)"
            />
          </div>
        </transition-group>
      </div>
    </div>
  </ClientOnly>
</template>

<script lang="ts">
  import closeSrc from './images/close-small.svg';
  import errorSrc from './images/error_browser.svg';
  import { usePusherStore } from '~/store/pusher';

  export default defineNuxtComponent({
    name: 'TheBadges',

    computed: {
      badges() {
        return usePusherStore().badges;
      },
      closeSrc: () => closeSrc,
      errorSrc: () => errorSrc,
    },

    methods: {
      removeBadge(badgeId: number) {
        return usePusherStore().removeBadge(badgeId);
      },
      clickBadge({ clickHandler, id }: { clickHandler: null | Function; id: number }) {
        if (clickHandler) {
          clickHandler();
        }
        this.removeBadge(id);
      },
      getBadgeClasses({
        isError,
        clickHandler,
      }: {
        isError: boolean;
        clickHandler: null | Function;
      }) {
        return {
          'the-badges__item list-item': true,
          'the-badges__item--error': isError === true,
          'the-badges__item--no-cb': clickHandler === null,
        };
      },
    },
  });
</script>

<style lang="scss" scoped>
  .the-badges {
    &__wrapper {
      left: 0;
      right: 0;
      bottom: 0;
      padding: 8px;
      width: auto;
      z-index: 12;
      position: fixed;

      &:empty {
        display: none;
      }

      @include tablet {
        left: 24px;
        bottom: 16px;
        width: 440px;
      }
    }

    &__item {
      display: flex;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      background-color: white;
      padding: 16px 44px 16px 16px;
      position: relative;
      user-select: none;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      @include mobile {
        padding: 8px 36px 12px 12px;
      }

      @include hover {
        background-color: #e1eafd;
      }
    }

    &__item--error {
      color: #fd4b4b;
      display: block;
    }

    &__item--no-cb {
      cursor: default;
      @include hover {
        background-color: white;
      }
    }

    &__item-image {
      flex: 0 0 50px;
      width: 50px;
      margin-right: 15px;
      border-radius: 3px;

      img {
        width: 100%;
      }

      @include mobile {
        flex: 0 0 30px;
        width: 30px;
        margin-right: 10px;
      }

      .the-badges__item--error & {
        margin: 0 0 8px;
        width: auto;
      }
    }

    &__item-text {
      font-size: 16px;
      line-height: 24px;
      word-break: break-word;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__item-close {
      position: absolute;
      font-size: 0;
      top: 20px;
      right: 20px;
      cursor: pointer;

      @include mobile {
        top: 12px;
        right: 12px;
      }

      img {
        width: 100%;
      }
    }
  }
</style>
