<!-- https://developers.google.com/identity/gsi/web/reference/js-reference -->
<template>
  <div class="google-one-tap-auth">
    <div
      id="google-one-tap-auth"
      class="google-one-tap-auth__prompt"
    />
    <iframe
      v-if="isIframe"
      ref="iframe"
      :src="iframeSrc"
      class="google-one-tap-auth__iframe"
      @load="iframeIsLoaded"
    />
  </div>
</template>

<script lang="ts">
  import { useIndexStore } from '~/store';
  import { getAccessToken } from '~/utils';
  import { AUTH_API_URL } from '~/constants/config';

  export default defineComponent({
    name: 'GoogleOneTapAuth',

    setup() {
      const route = useRoute();
      return { route };
    },

    data() {
      return {
        window: null as any,
        iframeSrc: '' as string,
        scriptLoaded: false as boolean,
        oneTapInited: false as boolean,
      };
    },

    computed: {
      user(): IUser | null {
        return useIndexStore().user ?? null;
      },

      isUserLoading(): boolean {
        return useIndexStore().isUserLoading;
      },

      authMethodsRestricted(): boolean {
        return !!useIndexStore().authMethodsRestricted;
      },

      isIframe(): boolean {
        const isAuthenticated = this.user;
        return Boolean(!isAuthenticated && this.iframeSrc.length);
      },
    },

    watch: {
      scriptLoaded() {
        this.initOneTap();
      },
      userData() {
        this.initOneTap();
      },
      authMethodsRestricted() {
        this.initOneTap();
      },
    },

    mounted() {
      // @ts-ignore
      window.onGoogleLibraryLoad = () => {
        this.scriptLoaded = true;
      };

      window.addEventListener(
        'googleOneTapAuth',
        () => {
          this.$sendYandexMetrika({
            level1: 'Авторизация',
            level5: 'Целенаправленная авторизация GoogleOneTap',
            level6: 'Google',
            level8: 'Авторизация',
          });
        },
        false,
      );
    },

    methods: {
      initOneTap() {
        const isScriptShownOrLoading = this.oneTapInited || !this.scriptLoaded;

        if (isScriptShownOrLoading) {
          return;
        }

        const isUserCountryRestrictedOrLoading =
          this.authMethodsRestricted === null || this.authMethodsRestricted;
        const isUserExistsOrLoading = this.user || this.isUserLoading;

        if (isUserCountryRestrictedOrLoading || isUserExistsOrLoading) {
          return;
        }

        this.oneTapInited = true;

        // @ts-ignore
        window.google?.accounts.id.initialize({
          client_id: '303105891655-00bc2bi141gtbm0l49nerjrrsmor0jqc.apps.googleusercontent.com',
          context: 'signin',
          prompt_parent_id: 'google-one-tap-auth',
          callback: this.handleCredentialResponse,
        });

        // @ts-ignore
        window.google?.accounts.id.prompt();
      },

      handleCredentialResponse(response: any) {
        const params = {
          ...response,
          isOneTap: true,
        };

        const searchParams = Object.keys(params)
          .map((key) => key + '=' + params[key])
          .join('&');

        this.iframeSrc = `${AUTH_API_URL}/id/auth/google/callback?${searchParams}&x-auth-app=web`;
      },

      iframeIsLoaded(): void {
        const accessToken = getAccessToken();

        const eventAwesome = new CustomEvent('googleOneTapAuth', {
          bubbles: true,
          detail: {
            accessToken,
            accessTokenSocialNetwork: 'google',
          },
        });

        const $iframe = this.$refs.iframe as HTMLElement;
        $iframe.dispatchEvent(eventAwesome);

        this.iframeSrc = '';
      },
    },
  });
</script>

<style lang="scss" scoped>
  .google-one-tap-auth {
    &__prompt {
      position: fixed;
      top: 100px;
      right: 8px;
      z-index: 9999999999;

      @include tablet {
        z-index: 9;
      }
    }
  }

  .google-one-tap-auth {
    &__iframe {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      pointer-events: none;
    }
  }
</style>
